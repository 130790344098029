$(document).ready(function(){
    $("#home-banner #banner-links").hide().delay(1000).slideDown();
    $("div#home-banner div[id$=nav]").hide();
    $("div#home-banner img").hide();
    $("div#home-banner img#default-img").show();
    $("#banner-links").hide().delay(1000).slideDown();
    $("div[id$=nav]").hide();

    /**
     * Closes a hover
     */
    var closeHover = function() {
        var $this = $(this);

        $this.siblings().removeClass('active inactive');

        $("div#home-banner div[id$=nav]:visible").slideUp("fast");
        $("div#home-banner img:visible").not('#default-img').fadeOut("fast");
    };

    var closeSubPageHover = function() {
        var $this = $(this);

        $this.siblings().removeClass('active inactive');

        $("div[id$=nav]:visible").slideUp("fast");
    };

    /**
     * Opens a hover
     */
    var openHover = function() {
        var $this = $(this);

        $this.siblings().removeClass('active inactive').addClass('inactive');
        if (!$this.hasClass('active')) {
            closeHover.call($this.siblings().find('.active'));
        }
        $this.removeClass('inactive').addClass('active');

        var ayeDee = $this.attr('id');
        $("div#home-banner #" + ayeDee + "-nav:hidden").slideToggle("fast");
        $("div#home-banner #" + ayeDee + "-img:hidden").fadeIn("fast");
    };

    var openSubPageHover = function() {
        var $this = $(this);

        $this.siblings().removeClass('active inactive').addClass('inactive');
        if (!$this.hasClass('active')) {
            closeSubPageHover.call($this.siblings().find('.active'));
        }
        $this.removeClass('inactive').addClass('active');

        var ayeDee = $this.attr('id');
        $("#" + ayeDee + "-nav:hidden").slideToggle("fast");
        $("#" + ayeDee + "-img:hidden").fadeIn("fast");
    };

    var closeTimer = null;
    $("div[id$=links] a").mouseenter(function(evt) {
        clearTimeout(closeTimer);
        openHover.call(this);
    }).mouseleave(function(evt) {
        var $this = this;
        closeTimer = setTimeout(function() { closeHover.call($this); }, 1250);
    });

    $('div[id$=nav]').mouseenter(function() {
        clearTimeout(closeTimer);
    }).mouseleave(function() {
        var $this = $('div[id$=links] #'+$(this).attr('id').slice(0, -4));
        closeTimer = setTimeout(function() { closeHover.call($this); }, 1250);
    });

    $("#scroller li").mouseenter(function() {
        $("img", this).eq(1).fadeOut("fast").end().eq(0).fadeIn("fast");
    }).mouseleave(function() {
        $("img", this).eq(1).fadeIn("fast").end().eq(0).fadeOut("fast");
    });

    var closeSubPageTimer = null;
    $("div[id$=links] a").mouseenter(function(evt) {
        clearTimeout(closeSubPageTimer);
        openSubPageHover.call(this);
    }).mouseleave(function(evt) {
        var $this = this;
        closeSubPageTimer = setTimeout(function() { closeSubPageHover.call($this); }, 1250);
    });

    $('div[id$=nav]').mouseenter(function() {
        clearTimeout(closeSubPageTimer);
    }).mouseleave(function() {
        var $this = $('div[id$=links] #'+$(this).attr('id').slice(0, -4));
        closeSubPageTimer = setTimeout(function() { closeSubPageHover.call($this); }, 1250);
    });

    var boxWidth = $("#experience-banner, #worklive-banner").width();
    var tabNumber = $("#experience-banner-links a, #worklive-banner-links a").length;
    var targetWidth = Math.floor(boxWidth / tabNumber);
    var remainder = boxWidth - (targetWidth * tabNumber);

    $("#experience-banner-links a, #worklive-banner-links a").width(targetWidth).last().width(targetWidth + remainder);

    $("a[rel^='prettyPhoto']").prettyPhoto({
            animation_speed: 'fast',
            opacity: 0.70,
            show_title: true,
            allow_resize: true,
            theme: 'light_square',
            markup: '<div class="pp_pic_holder"> \
                        <div class="ppt">&nbsp;</div> \
                        <div class="pp_top"> \
                            <div class="pp_left"></div> \
                            <div class="pp_middle"></div> \
                            <div class="pp_right"></div> \
                        </div> \
                        <div class="pp_content_container"> \
                            <div class="pp_left"> \
                            <div class="pp_right"> \
                                <div class="pp_content"> \
                                    <div class="pp_loaderIcon"></div> \
                                    <div class="pp_fade"> \
                                        <a href="#" class="pp_expand" title="Expand the image">Expand</a> \
                                        <div class="pp_hoverContainer"> \
                                            <a class="pp_next" href="#">next</a> \
                                            <a class="pp_previous" href="#">previous</a> \
                                        </div> \
                                        <div id="pp_full_res"></div> \
                                        <div class="pp_details"> \
                                            <div class="pp_nav"> \
                                                <a href="#" class="pp_arrow_previous">Previous</a> \
                                                <p class="currentTextHolder">0/0</p> \
                                                <a href="#" class="pp_arrow_next">Next</a> \
                                            </div> \
                                            <a class="pp_close" href="#">Close</a> \
                                        </div> \
                                    </div> \
                                </div> \
                            </div> \
                            </div> \
                        </div> \
                        <div class="pp_bottom"> \
                            <div class="pp_left"></div> \
                            <div class="pp_middle"></div> \
                            <div class="pp_right"></div> \
                        </div> \
                    </div> \
                    <div class="pp_overlay"></div>'
    });

    var mapElem = (document.getElementById('lmhContactMap')) ? document.getElementById('lmhContactMap') : document.getElementById('contactMap');

    if (mapElem) {
        var latlng = new google.maps.LatLng(53.263775, -1.165838);
        var centre = new google.maps.LatLng(53.350551,-1.203003);
        var opts = {
            zoom: 10,
            center: centre,
            panControl: true,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.DEFAULT,
                position: google.maps.ControlPosition.LEFT_TOP
            },
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            styles: [ { stylers: [ { hue: "#ffc300" }, { saturation: -34 } ] },{ featureType: "road.highway", stylers: [ { hue: "#ffa200" }, { saturation: -38 }, { lightness: 14 }, { gamma: 1.49 } ] },{ featureType: "road.local", elementType: "labels", stylers: [ { hue: "#ffcc00" } ] },{ featureType: "road.arterial", stylers: [ { gamma: 1 } ] },{ featureType: "road.highway", elementType: "labels", stylers: [ { gamma: 2.67 }, { lightness: -31 }, { hue: "#ffff00" } ] } ],
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map(mapElem, opts);

        var marker = new google.maps.Marker({
          position: latlng,
          map: map,
          title: 'Welbeck Estate',
          icon: 'http://welbeck.rckt.co.uk/assets/templates/welbeck/images/map_pointer.png'
        });
    }

    if($(".employee")){
        $(".employee-details").hide();

        $('.employee').mouseenter(function() {
            $('.employee-details', this).slideDown('fast');
        }).mouseleave(function() {
            $('.employee-details', this).slideUp('fast')
        });

    }

    if($(".producesection")){
        $(".produceinfo").hide();

        $(".producesection h2").click(function(){
            $(this).siblings(".produceinfo").slideToggle("fast");
            $(this).toggleClass("open");
        });
    }

    var checkCookie = getCookie('welbeckcookiecheck');
    var checkCookieButton = document.getElementById('cookiewarnbutton');

    console.log(checkCookie);

    if (checkCookie.length > 0) {
        $('#cookiewarn').hide();
    } else {
        $('#cookiewarn').show();
    }

    checkCookieButton.addEventListener('click', function(e) {
        e.preventDefault();

        createCookie('welbeckcookiecheck', 'true', 30);
        $('#cookiewarn').hide();
    });
});

$(window).load(function() {
    $('#flickrphotos, #scroller').scroller({
        slidesPerScroll: 1,
        slidesSelector: 'ul li',
        containerSelector: 'ul'
    });
});

/*
    https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie
    Stolen from:
*/
var createCookie = function(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(c_name) {
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}
