
/**
 * @author John Noel <john.noel@rckt.co.uk>
 * @copyright rckt 2011 <http://www.rckt.co.uk>
 * @package Welbeck
 */

/**
 * Scroller plugin
 * Turns a list into a scroller / carousel / slider / whatever
 *
 * @author John Noel <john.noel@rckt.co.uk>
 * @copyright rckt http://www.rckt.co.uk/
 * @version 2.1
 * @package Welbeck
 * @todo Allow choosing of pagination CSS classes
 * @todo Allow external calling of advance / retreat
 */
(function($) {
    $.fn.scroller = function(option) {
        option = $.extend({}, $.fn.scroller.option, option);

        return this.each(function() {
            var $this = $(this),
                transitioning = false,
                slideCount = $(option.slidesSelector, $this).length;

            $('<div class="pagination" />')
                .append('<span class="previous">Previous</span>')
                .append('<span class="next">Next</span>')
                .appendTo($this);

            // "square off" scroller
            if((slideCount % option.slidesPerScroll) != 0)
            {
                var p = $(option.containerSelector, $this);
                var toInsert = $(option.slidesSelector, $this)
                    .slice(0 - ((Math.ceil(slideCount / option.slidesPerScroll) * option.slidesPerScroll) - slideCount))
                    .each(function() {
                        p.append(this.cloneNode(false)); // not a deep copy
                    });
            }

            // absolutise
            var h = 0, rw = 0; // rolling width
            $(option.slidesSelector, $this).each(function(idx) {
                $(this).css({
                    position: 'absolute',
                    top: 0, left: rw+'px'
                });

                rw += $(this).outerWidth(true);
                h = ($(this).outerHeight(true) > h) ? $(this).outerHeight(true) : h;
            });

            $(option.containerSelector, $this).css({ position: 'relative', height: h+'px' });

            // advance
            var advance = function(count) {
                if(!transitioning)
                {
                    transitioning = true;

                    var slides = $(option.slidesSelector, $this);
                    // grab the first slidesPerScroll slides
                    var cloneableSlides = $(option.slidesSelector, $this).slice(0, option.slidesPerScroll * count);
                    var w = slides.outerWidth(true);

                    // calculate scroll items widths (rolling width)
                    var rw = 0;
                    slides.each(function() { rw += $(this).outerWidth(true); });

                    // append them and set their left offset
                    $(option.containerSelector, $this).append(cloneableSlides.clone(true).each(function(idx) {
                        $(this).css({ left: rw+'px' });
                        rw += $(this).outerWidth(true);
                    }));

                    // shouldn't use slides variable as slides have been added
                    // TODO grab the first option.slidesPerScroll and calculate width
                    $(option.slidesSelector, $this).animate({
                        left: '-=' + ((w * option.slidesPerScroll) * count)
                    }, 700, function() {
                        transitioning = false;
                        cloneableSlides.remove();
                    });
                } // !transitioning
            }; // advance()

            // retreat
            var retreat = function(count) {
                if(!transitioning)
                {
                    transitioning = option.slidesPerScroll;

                    // grab the final slidesPerScroll slides
                    var cloneableSlides = $(option.slidesSelector, $this).slice(0 - (option.slidesPerScroll * count));
                    var w = cloneableSlides.outerWidth(true);

                    // calculate cloneable width
                    var rw = 0;
                    cloneableSlides.each(function() { rw += $(this).outerWidth(true); });

                    // clone and prepend them, setting their left offset correctly
                    $(option.containerSelector, $this).prepend(cloneableSlides.clone(true).each(function(idx) {
                        $(this).css({ left: (0 - rw)+'px' });
                        rw -= $(this).outerWidth(true);
                    }));

                    // TODO calculate proper width rather than assuming all the same width
                    $(option.slidesSelector, $this).animate({
                        left: '+=' + ((w * option.slidesPerScroll) * count)
                    }, 700, function() {
                        transitioning = false;
                        cloneableSlides.remove();
                    });
                } // !transitioning
            }; // retreat()

            $('.pagination .next', $this).click(function(evt) { evt.stopPropagation(); advance(1); }).
                mousedown(function(evt) { evt.stopPropagation(); advance(1); $(this).data('timer', setInterval(function() { advance(1); }, 300)); }).
                mouseup(function(evt) { evt.stopPropagation(); clearInterval($(this).data('timer')); })
            $('.pagination .previous', $this).click(function(evt) { evt.stopPropagation(); retreat(1); }).
                mousedown(function(evt) { evt.stopPropagation(); retreat(1); $(this).data('timer', setInterval(function() { retreat(1); }, 300)); }).
                mouseup(function(evt) { evt.stopPropagation(); clearInterval($(this).data('timer')); });
        });
    };

    $.fn.scroller.option = {
        slidesPerScroll: 1, // how many slides per click
        slidesSelector: 'ul.slides li', // how to select the slides (scoped to the element)
        containerSelector: 'ul.slides' // how to select the slide container
    };
})(jQuery);
